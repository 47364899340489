<template>
    <div class="personalCenter">
        <el-container>
            <el-aside width="200px">
                <div class="Login_Hover">
                    <ul>
                        <!-- <li v-for="(item, index) in personalHomepage" :key="index">
                            <template v-if="item.children">
                                <template>
                                    <router-link class="aa" :to="'/PersonalCenter/' + item.pathRoute">
                                        <div class="img"><img
                                                :src="path == '/PersonalCenter/' + item.pathRoute ? item.xuanImg : item.weiImg"
                                                alt="">
                                        </div>
                                        <div class="nameItem"><span>{{ item.name }}</span></div>
                                    </router-link>
                                </template>
                            </template>
                            <template v-else>
                                <router-link class="aa" :to="'/PersonalCenter/' + item.pathRoute">
                                    <div class="img"><img
                                            :src="path == '/PersonalCenter/' + item.pathRoute ? item.xuanImg : item.weiImg"
                                            alt="">
                                    </div>
                                    <div class="nameItem"><span>{{ item.name }}</span></div>
                                </router-link>
                            </template>
                        </li> -->

                        <li>
                            <router-link class="aa" :to="'/PersonalCenter/MyHomePage'">
                                <div class="img"><img
                                        :src="path == '/PersonalCenter/MyHomePage' ? MyHomePagexuanImg : MyHomePageweiImg"
                                        alt="">
                                </div>
                                <div class="nameItem"><span>我的主页</span></div>
                            </router-link>
                        </li>
                        <li>
                            <div class="levelIII">
                                <div @click="handleTuoChe">
                                    <router-link class="aa" :to="'/PersonalCenter/TrailerOrder'"
                                        style="background-color: white;">
                                        <div class="img"><img
                                                :src="path == '/PersonalCenter/TrailerOrder/MyOrder' || path == '/PersonalCenter/TrailerOrder/Transport' ? TrailerOrderxuanImg : TrailerOrderweiImg"
                                                alt="">
                                        </div>
                                        <div class="nameItem"><span>拖车订单</span></div>
                                        <i :class="tuocheFlag ? 'el-icon-arrow-down ' : 'el-icon-arrow-right'"></i>
                                    </router-link>
                                </div>
                                <div class="levelIIIDIV" v-show="tuocheFlag">
                                    <ul>
                                        <li>
                                            <router-link
                                                to="/PersonalCenter/TrailerOrder/MyOrder">&emsp;&emsp;&emsp;&emsp;我的订单</router-link>
                                        </li>
                                        <li>
                                            <router-link
                                                to="/PersonalCenter/TrailerOrder/Transport">&emsp;&emsp;&emsp;&emsp;运输追踪</router-link>
                                        </li>
                                        <li>
                                            <router-link
                                                to="/PersonalCenter/TrailerOrder/packingListQuery">&emsp;&emsp;&emsp;&emsp;箱单查询</router-link>
                                        </li>
                                        <li>
                                            <router-link
                                                to="/PersonalCenter/TrailerOrder/ExpenseConfirmation">&emsp;&emsp;&emsp;&emsp;费用确认</router-link>
                                        </li>
                                    </ul>
                                </div>

                            </div>

                        </li>
                        <li>
                            <router-link class="aa" :to="'/PersonalCenter/CounterInquiryOrder'">
                                <div class="img"><img
                                        :src="path == '/PersonalCenter/CounterInquiryOrder' ? CounterInquiryOrderxuanImg : CounterInquiryOrderweiImg"
                                        alt="">
                                </div>
                                <div class="nameItem"><span>询价订单</span></div>
                            </router-link>
                        </li>
                        <li>
                            <router-link class="aa" :to="'/PersonalCenter/OceanShippings'">
                                <div class="img"><img
                                        :src="path == '/PersonalCenter/OceanShippings' ? OceanShippingsxuanImg : OceanShippingsweiImg"
                                        alt="">
                                </div>
                                <div class="nameItem"><span>海运订单</span></div>
                            </router-link>
                        </li>
                        <li>
                            <router-link class="aa" :to="'/PersonalCenter/Railways'">
                                <div class="img"><img
                                        :src="path == '/PersonalCenter/Railways' ? RailwaysxuanImg : RailwaysweiImg"
                                        alt="">
                                </div>
                                <div class="nameItem"><span>铁路订单</span></div>
                            </router-link>
                        </li>
                        <li>
                            <router-link class="aa" :to="'/PersonalCenter/AirTransports'">
                                <div class="img"><img
                                        :src="path == '/PersonalCenter/AirTransports' ? AirTransportsxuanImg : AirTransportsweiImg"
                                        alt="">
                                </div>
                                <div class="nameItem"><span>空运订单</span></div>
                            </router-link>
                        </li>
                        <li>
                            <div class="levelIII">
                                <div @click="handleLevel">
                                    <router-link class="aa" :to="'/PersonalCenter/ReportManagement'"
                                        style="background-color: white;">
                                        <div class="img"><img
                                                :src="path == `/PersonalCenter/ReportManagement/InTransitReport` || path == `/PersonalCenter/ReportManagement/HistoricalReport` ? ReportManagementxuanImg : ReportManagementweiImg"
                                                alt="">
                                        </div>
                                        <div class="nameItem"><span>报表管理</span></div>
                                        <i :class="leveFlag ? 'el-icon-arrow-down ' : 'el-icon-arrow-right'"></i>
                                    </router-link>
                                </div>
                                <div class="levelIIIDIV" v-show="leveFlag">
                                    <ul>
                                        <li>
                                            <router-link
                                                to="/PersonalCenter/ReportManagement/InTransitReport">&emsp;&emsp;&emsp;&emsp;在途报表</router-link>
                                        </li>
                                        <li>
                                            <router-link
                                                to="/PersonalCenter/ReportManagement/HistoricalReport">&emsp;&emsp;&emsp;&emsp;历史报表</router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </li>
                        <li>
                            <router-link class="aa" :to="'/PersonalCenter/RentBoxs'">
                                <div class="img"><img
                                        :src="path == '/PersonalCenter/RentBoxs' ? RentBoxsxuanImg : RentBoxsweiImg"
                                        alt="">
                                </div>
                                <div class="nameItem"><span>租箱订单</span></div>
                            </router-link>
                        </li>
                        <li>
                            <router-link class="aa" :to="'/PersonalCenter/FinancialStatement'">
                                <div class="img"><img
                                        :src="path == '/PersonalCenter/FinancialStatement' ? FinancialStatementxuanImg : FinancialStatementweiImg"
                                        alt="">
                                </div>
                                <div class="nameItem"><span>财务账单</span></div>
                            </router-link>
                        </li>
                        <li>
                            <router-link class="aa" :to="'/PersonalCenter/MessageManagement'">
                                <div class="img"><img
                                        :src="path == '/PersonalCenter/MessageManagement' ? MessageManagementxuanImg : MessageManagementweiImg"
                                        alt="">
                                </div>
                                <div class="nameItem"><span>消息管理</span></div>
                            </router-link>
                        </li>
                        <li>
                            <router-link class="aa" :to="'/PersonalCenter/MyAddresss'">
                                <div class="img"><img
                                        :src="path == '/PersonalCenter/MyAddresss' ? MyAddresssxuanImg : MyAddresssweiImg"
                                        alt="">
                                </div>
                                <div class="nameItem"><span>我的地址</span></div>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </el-aside>
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
        <Footer_Compontent></Footer_Compontent>
    </div>
</template>

<script>
import Footer_Compontent from '../../components/Footer_Compontent.vue'
export default {
    components: { Footer_Compontent },
    data() {
        return {
            path: '',
            isActive: null,
            leveFlag: false,
            tuocheFlag: false,
            MyHomePageweiImg: require("../../assets/homePage/myHomePage-N.png"), //我的主页
            MyHomePagexuanImg: require("../../assets/homePage/myHomePage-Y.png"),

            TrailerOrderweiImg: require("../../../src/assets/homePage/tuoche-N.png"), //拖车订单
            TrailerOrderxuanImg: require("../../../src/assets/homePage/tuoche-Y.png"),

            CounterInquiryOrderweiImg: require("../../../src/assets/homePage/order-N.png"), //询价订单
            CounterInquiryOrderxuanImg: require("../../../src/assets/homePage/order-Y.png"),

            OceanShippingsweiImg: require("../../../src/assets/homePage/oceanShipping-N.png"), //海运订单
            OceanShippingsxuanImg: require("../../../src/assets/homePage/oceanShipping-Y.png"),

            RailwaysweiImg: require("../../src/../assets/homePage/railway-N.png"), //铁路订单
            RailwaysxuanImg: require("../../src/../assets/homePage/railway-Y.png"),

            AirTransportsweiImg: require("../../src/../assets/homePage/airTransport-N.png"), //空运
            AirTransportsxuanImg: require("../../src/../assets/homePage/airTransport-Y.png"),

            ReportManagementweiImg: require("../../src/../assets/homePage/baobiao-N.png"), //报表
            ReportManagementxuanImg: require("../../src/../assets/homePage/baobiao-Y.png"),

            RentBoxsweiImg: require("../../../src/assets/homePage/rentBox-N.png"), //租箱
            RentBoxsxuanImg: require("../../../src/assets/homePage/rentBox-Y.png"),

            FinancialStatementweiImg: require("../../../src/assets/homePage/bill-N.png"),
            FinancialStatementxuanImg: require("../../../src/assets/homePage/bill-Y.png"),

            MessageManagementweiImg: require("../../../src/assets/homePage/myHomePage-N.png"), //消息
            MessageManagementxuanImg: require("../../../src/assets/homePage/myHomePage-Y.png"),

            MyAddresssweiImg: require("../../../src/assets/homePage/address-N.png"), //我的地址
            MyAddresssxuanImg: require("../../../src/assets/homePage/address-Y.png"),
            personalHomepage: [
                {
                    weiImg: require("@/assets/homePage/myHomePage-N.png"),
                    xuanImg: require("@/assets/homePage/myHomePage-Y.png"),
                    name: "我的主页",
                    pathRoute: "MyHomePage"
                },
                {
                    weiImg: require("../../../src/assets/homePage/tuoche-N.png"),
                    xuanImg: require("../../../src/assets/homePage/tuoche-Y.png"),
                    name: "拖车订单",
                    pathRoute: "TrailerOrder"
                },
                {
                    weiImg: require("../../../src/assets/homePage/order-N.png"),
                    xuanImg: require("../../../src/assets/homePage/order-Y.png"),
                    name: "询价订单",
                    pathRoute: "CounterInquiryOrder"
                },
                {
                    weiImg: require("../../../src/assets/homePage/oceanShipping-N.png"),
                    xuanImg: require("../../../src/assets/homePage/oceanShipping-Y.png"),
                    name: "海运订单",
                    pathRoute: "OceanShippings"
                },
                {
                    weiImg: require("../../src/../assets/homePage/railway-N.png"),
                    xuanImg: require("../../src/../assets/homePage/railway-Y.png"),
                    name: "铁路订单",
                    pathRoute: "Railways"
                },
                {
                    weiImg: require("../../src/../assets/homePage/airTransport-N.png"),
                    xuanImg: require("../../src/../assets/homePage/airTransport-Y.png"),
                    name: "空运订单",
                    pathRoute: "AirTransports" //
                },
                {
                    weiImg: require("../../src/../assets/homePage/baobiao-N.png"),
                    xuanImg: require("../../src/../assets/homePage/baobiao-Y.png"),
                    name: "报表管理",
                    pathRoute: "ReportManagement", //ReportManagement
                    children: [{
                        name: "在途报表",
                    }, {
                        name: "历史报表"
                    }]

                },
                // 在途表表  /  历史报表 
                {
                    weiImg: require("../../../src/assets/homePage/rentBox-N.png"),
                    xuanImg: require("../../../src/assets/homePage/rentBox-Y.png"),
                    name: "租箱订单",
                    pathRoute: "RentBoxs"
                },
                // {
                //     weiImg: require("../../../src/assets/homePage/bill-N.png"),
                //     xuanImg: require("../../../src/assets/homePage/bill-Y.png"),
                //     name: "财务账单",
                //     pathRoute: "FinancialStatement"
                // },
                {
                    weiImg: require("../../../src/assets/homePage/myHomePage-N.png"),
                    xuanImg: require("../../../src/assets/homePage/myHomePage-Y.png"),
                    name: "消息管理",
                    pathRoute: "MessageManagement"
                },
                // {
                //     weiImg: require("../../../src/assets/homePage/staff-N.png"),
                //     xuanImg: require("../../../src/assets/homePage/staff-Y.png"),
                //     name: "员工管理",
                //     pathRoute: "EmployeeManagement"
                // },
                // {
                //     weiImg: require("../../../src/assets/homePage/role-N.png"),
                //     xuanImg: require("../../../src/assets/homePage/role-Y.png"),
                //     name: "角色管理",
                //     pathRoute: "RoleManagement"
                // },
                {
                    weiImg: require("../../../src/assets/homePage/address-N.png"),
                    xuanImg: require("../../../src/assets/homePage/address-Y.png"),
                    name: "我的地址",
                    pathRoute: "MyAddresss"
                }
            ],

        }
    },
    created() {
        this.path = this.$route.path;
    },
    methods: {
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        handlePush(pathRoute, index) {
            console.log(pathRoute);
            this.isActive = index;
            console.log("@", this.isActive);
            this.$router.push(`/PersonalCenter/${pathRoute}`)
        },
        handleLevel() {
            this.leveFlag = !this.leveFlag;
            if (this.tuocheFlag && this.leveFlag) {
                this.tuocheFlag = false
            }
        },
        handleTuoChe() {
            this.tuocheFlag = !this.tuocheFlag;
            console.log(this.leveFlag);
            if (this.leveFlag && this.tuocheFlag) {
                this.leveFlag = false
            }
        },
        hanldeSelect() {
            this.leveFlag = false;


        }
    },
    watch: {
        $route() {
            this.path = this.$route.path
        }
    }
}
</script>

<style lang="scss" scoped>
.footer {
    margin-top: 20px;
}

.router-link-active {
    background: #E9F4FF;

    .nameItem {
        color: #1890FF !important;
    }
}

.personalCenter {
    width: 100%;
    // margin: 24px auto 0;
    background-color: #F6F6F6;
    overflow: hidden;

    .el-container {
        // width: 100%;
        min-width: 1200px;
        margin-top: 16px;
    }
}

.el-container {
    margin: 0 auto;
}

.el-aside {
    position: relative;
    margin-left: 24px;
    min-height: 666px;
}

.el-main {
    // width: 1526px;
    padding: 0;
    margin-left: 16px;
    // padding-top: 24px;
    padding-right: 24px;
}

::v-deep .el-submenu {
    width: 100%;
}

.Login_Hover {
    position: absolute;
    right: 0;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    // padding: 12px 0;
    width: 100%;

    ul {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding: 10px 1px;

        .levelIII {
            position: relative;
            width: 100%;

            i {
                position: absolute;
                right: 30px;
                top: 12px;
                color: #333333;
            }

            .levelIIIDIV {
                // display: none;
                margin-left: -60px;
                ul {
                    li {
                        a {
                            width: 100%;
                            font-size: 16px;
                            font-weight: 400;
                            color: #333333;
                            text-align: center;
                            line-height: 40px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        li {
            display: flex;
            align-items: center;
            width: 100%;
            min-height: 40px;
            // background: #E9F4FF;
            justify-content: center;

            // margin-top: 1px;
            .aa {
                width: 100%;
                min-height: 40px;
                display: flex;
                align-items: center;
                // justify-content: center;
                padding-left: 32px;
            }

            .img {
                width: 16px;
                height: 16px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .nameItem {
                margin-left: 13px;
                font-size: 16px;
                font-weight: 400;
                color: #333333;
            }
        }
    }
}
</style>